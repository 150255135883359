import { ReactElement, SyntheticEvent } from 'react';
import { Box } from '@mui/system';
import { FaLinkedin } from 'react-icons/fa'
import './App.css';

const styles = {
  box: {
    backgroundColor: 'background.paper',
    boxShadow: 1,
    borderRadius: 2,
    padding: 2,
    minWidth: 300,
  },
  title: { color: '#eee', fontSize: 54 }
}

export function App(): ReactElement {

  function handleClick(_e: SyntheticEvent) {
    window.location.href = 'https://www.linkedin.com/in/jverre/'
  }

  return (
    <div className='app'>
      <div className='app-main'>
        <p style={styles.title} data-test-id='title'>
          J Verre
        </p>
        <Box sx={styles.box}>
          <FaLinkedin
            color='rgba(20, 180, 90, 0.75)'
            className='fade-in'
            cursor='pointer'
            data-test-id='linkedin'
            size={40}
            onClick={handleClick}
          />
        </Box>
      </div>
    </div>

  );
}
App.displayName = 'App'
